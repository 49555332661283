<template>
  <NuxtLink :href="journey.journeySlug">
    <section class="journey-card" :class="{ large }">
      <div class="image-container flex">
        <div class="gradient" />
        <div class="top-banner-image">
          <div v-if="label" class="favorite" :class="{ 't-large': large }">
            {{ label }}
          </div>
          <Icon
            :name="isLiked ? 'heart-active' : 'heart-inactive'"
            class="like-button prevent-slide"
            @click.prevent="handleLike(!isLiked)"
          />
        </div>
        <ProductCardImageSlider class="flex" :images="journey.imageSlugs ?? []" :large="large" />
        <ShadowedText class="journey-name" :text="spanTitle" />
      </div>
      <div class="bottom-container">
        <div class="information-container">
          <div class="top-info flex">
            <div class="flex">
              <Icon
                :name="travelTypeIcon"
                class="info-icon"
                stroke="var(--color-gold)"
                width="22px"
                height="22px"
              />
              <span>
                {{ travelTypeName }}
              </span>
            </div>
            <!-- <div v-if="journey.ratings?.average">
              <Icon name="star-empty" class="info-icon" width="12px" height="12px" />
              <span> {{ journey.ratings.average }}/5 </span>
            </div> -->
          </div>
          <div class="days-nights-info flex">
            <div class="flex items-center">
              <Icon
                name="day"
                class="info-icon"
                stroke="var(--color-green)"
                width="22px"
                height="22px"
              />
              <span> {{ journey.days }} jours </span>
            </div>
            <div class="flex items-center">
              <Icon
                name="night"
                class="info-icon night-icon"
                stroke="var(--color-green)"
                width="16px"
                height="16px"
              />
              <span> {{ journey.nights }} nuits </span>
            </div>
          </div>
          <!-- <div v-if="journey.locations.length > 0" class="locations flex">
            <Icon name="map-point-outline" class="info-icon" width="16px" height="16px" />
            <span class="locations-info flex">{{
              [...new Set(journey.locations)].join(', ')
            }}</span>
          </div> -->
        </div>
        <div class="price-info flex">
          <div class="from-price-wrapper flex">
            <span class="from-price">à partir de</span>
            <div class="price">{{ formattedPrice }}</div>
          </div>
          <span class="discover-button">En découvrir +</span>
        </div>
      </div>
    </section>
  </NuxtLink>
</template>

<script lang="ts" setup>
import { TRAVEL_TYPE_ICONS, PRODUCT_LABEL_NAMES } from '@/lib/variables';

import { AlgoliaJourney } from '@/lib/algolia-types/AlgoliaJourney';

import TRAVEL_STYLE_NAME from '@/lib/types/enums/travelStyleName.enum';

const { journey } = withDefaults(
  defineProps<{
    journey: AlgoliaJourney;
    large?: boolean;
  }>(),
  { large: false }
);

const isLiked = ref(false);
const formattedPrice = computed(() => formatPrice(journey.price ?? 0));

const label = journey.label
  ? PRODUCT_LABEL_NAMES[journey.label as keyof typeof PRODUCT_LABEL_NAMES]
  : undefined;

const travelTypeName = journey.generalTravelType
  ? TRAVEL_STYLE_NAME[journey.generalTravelType as keyof typeof TRAVEL_STYLE_NAME]
  : '';

const travelTypeIcon = journey.generalTravelType
  ? TRAVEL_TYPE_ICONS[journey.generalTravelType as keyof typeof TRAVEL_TYPE_ICONS]
  : '';

// watchEffect(() => (isLiked.value = journey.isLiked));

function handleLike(value: boolean): void {
  isLiked.value = value;
}

const spanTitle = `<span class="title">${journey.title}</span>`;
</script>

<style lang="scss" scoped>
@use 'sass:color';
@use '$/components/shadowed-text.scss';
@use '$/border-radius.scss';
@use '$/button.scss';
@use '$/colors.scss';
@use '$/mouse-behavior.scss';
@use '$/breakpoints.scss';

.journey-card {
  cursor: pointer;

  flex-direction: column;

  width: 300px;
  height: 460px;
  margin-left: 12px;

  background-color: white;
  border: solid 1px colors.$grey-500;
  border-radius: border-radius.$small;

  &.large {
    width: 355px;

    .image-container {
      height: 290px;

      .top-banner-image .favorite {
        padding: 9px 10px;
        font-size: 15px;
      }
    }
  }

  .image-container {
    position: relative;

    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    height: 245px;
    margin-top: -1px;

    .top-banner-image {
      z-index: 2;

      align-items: center;
      justify-content: space-between;

      height: fit-content;
      margin: 16px;

      .favorite {
        align-items: center;

        height: 27px;
        padding: 7px 5px;

        font-size: 13px;
        font-weight: 600;
        color: colors.$gold;

        background-color: white;
        border-radius: border-radius.$small;
      }

      .like-button {
        @include mouse-behavior.clickable-lightness($color: #e7004c, $lightness-diff: 10%);

        position: absolute;
        top: 0;
        right: 0;
        margin: 16px;
      }
    }

    .journey-name {
      @include shadowed-text.small();

      z-index: 2;
      margin: 10px 16px 16px;
      font-size: 23px;
    }

    .gradient {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      transform: translateZ(0);

      width: 100%;
      height: 100%;

      background: linear-gradient(1deg, #000 0.8%, rgb(0 0 0 / 0%) 35%);

      // border-radius: border-radius.$small;
    }
  }

  .bottom-container {
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px 16px 16px;

    .information-container {
      flex-direction: column;

      .top-info {
        justify-content: space-between;

        span {
          color: colors.$gold;
        }
      }

      .days-nights-info {
        gap: 10px;
        margin-top: 10px;

        span {
          color: colors.$green;
        }
      }

      .info-icon {
        margin-right: 5px;
      }

      .night-icon {
        margin-left: 5px;
      }

      // TODO: add it after
      // .locations {
      //   display: flex;
      //   align-items: center;
      //   margin-top: 10px;

      //   .locations-info {
      //     overflow: hidden;
      //     display: inline-block;

      //     color: colors.$grey-700;
      //     text-overflow: ellipsis;
      //     white-space: nowrap;
      //   }
      // }
    }
  }

  .price-info {
    justify-content: space-between;
    margin-top: 24px;
    color: colors.$green;

    .from-price-wrapper {
      flex-direction: column;

      .from-price {
        color: colors.$green;
      }

      .price {
        font-size: 28px;
        font-weight: 600;
        line-height: 0.9;
      }
    }

    .discover-button {
      @include button.outlined($color-hover: colors.$green, $size: small);

      align-self: flex-end;

      width: 127px;
      width: 50%;
      height: 36px;
      padding-right: 12px;
      padding-left: 12px;
    }
  }
}

@include breakpoints.mobile() {
  .journey-card {
    height: auto;

    .image-container {
      .top-banner-image {
        .favorite {
          width: 28%;
          height: auto;
          padding: 2px 5px;
        }
      }
    }
  }

  // TODO: add it after
  // .bottom-container {
  //   .locations {
  //     .locations-info {
  //       max-width: 300px;
  //     }
  //   }
  // }
}
</style>
